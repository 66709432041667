/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/rules-of-hooks */
import axios, { AxiosResponse, AxiosRequestConfig, AxiosInstance } from 'axios'
import { message } from '@shopee/solar-design'
import Qs from 'qs'
import { TASK_API_BASE_URL, QUEUE_API_BASE_URL, TEMPLATE_API_BASE_URL, errorCodeMap } from '@/constants'
import { normalizeKey } from '@/utils'
import { isUndefined, noop, omitBy } from 'lodash-es'

// 异步加载，解决循环引用的问题
let reportRequestTime, reportRequestFail
import('./report').then(({ reportRequestTime: a, reportRequestFail: b }) => {
  reportRequestTime = a
  reportRequestFail = b
})

type ReqInterceptors = {
  reWriteConfig: (config: AxiosRequestConfig) => AxiosRequestConfig
  errHandler: (error: any) => void
}[]

type ResInsterceptors = {
  reWriteRes: (res: AxiosResponse) => AxiosResponse
  errHandler: (error: any) => void
}[]

export type ResData<D> = {
  code: number
  data: D
  msg: string
}

export type ProxyResData<D> = {
  code: number
  data: D
  msg: string
  command: string
}

export type BaseOptionsFetcherParams = {
  query?: string
  page: number
  pageSize?: number
}

// 请求表格数据的参数，请确保后端用的是这些参数
export type BaseTableFetcherParams = {
  query?: string
  sortFields?: string // 后端是可以传多个，用 , 分隔， 但是目前只能按一个来排序，所以不用传多个
  sortValues?: 'descend' | 'ascend' | null // 升序/降序， 后端是可以传多个，用 , 分隔
  pageSize?: number
  page?: number
  filterFields?: string // filter_fields=key1,key2,key2
  filterValues?: string // filter_values=v1,v2,v3
}

export type BaseInfocardFetcherParams = {
  filter_fields?: string // 查询select的选项域
  filter_values?: string // 查询select选项域对应的values值
}

export type BaseTableFetcherRes<Item> = {
  total: number
  list: Item[]
}

export type ExtendsTableFetcherParams<T extends Record<string, any>> = BaseTableFetcherParams & T

interface IAxiosRequestConfig extends AxiosRequestConfig {
  /**
   * @description 是否忽略公共展示错误的逻辑
   * @value true：忽略公共逻辑，错误展示要在自己的代码处理
   * @value false：不忽略公共逻辑
   */
  ignoreMsg?: boolean
  /**
   * @description 错误消息的类型，类型相同的消息在一定时间内只展示一个
   */
  msgKey?: string
  /**
   * @description 错误消息展示延时，默认值与antd message组件的duration相同3000ms
   * @description 单位：ms
   */
  msgDuration?: number
  /**请求开始时间 */
  requestStartTime?: number
  /**请求耗时打点额外的ext信息 */
  requestReportExt?: string
  /**是否需要上报请求耗时 */
  needReportReqTime?: boolean
}

/**
 * @description 后台error归一化code
 * @value 336500400: error 客户端传参错误
 * @value 336500500: error 服务器内部错误
 * @value 336500600: info 产品预定义的一些提示信息(如: queue_type下没有任何队列需要info提示)
 * @value 336500700: warning 产品预定义提示信息(结合自身业务评估使用)
 * @value 自定义code: 场景-需要根据不同的code展示不同的提示样式, 该类型code使用需要前端和后端对齐
 */
export const errorCodeMessageFucntion: { [key: number]: any } = {
  336500400: message.error,
  336500500: message.error,
  336500600: message.info,
  336500700: message.warning
}
const msgMap = new Map<string, string>()
export function msgService(code: number, msg: string, key = '', duration = 3000) {
  if (!msg) return
  const k = key || msg
  // 存在同样的key或者同样的msg都不弹提示
  if (msgMap.get(k) || Array.from(msgMap.values()).includes(msg)) {
    return
  }
  // 后台error归一化code，兜底用error
  const f = errorCodeMessageFucntion[code] || message.error
  if (errorCodeMap[code]) {
    f(errorCodeMap[code])
  } else {
    f(msg)
  }
  if (k) {
    msgMap.set(k, msg)
    setTimeout(() => {
      msgMap.delete(k)
    }, duration)
  }
}

type PreRequestsMap = Map<string, (response: AxiosResponse<ResData<any>>, instance: AxiosInstance) => void>
class Service {
  serviceInstance: AxiosInstance
  baseURL: string
  preRequestsMap: PreRequestsMap
  preRequestPromise: Promise<void>
  preRequestResolveFun: () => void = noop
  preRequestRejectFun: (reason?: string) => void = noop
  whiteList: string[]
  constructor({
    baseURL,
    preRequests,
    whiteList,
    requestInterceptors,
    responseInterceptors
  }: {
    baseURL: string
    preRequests: PreRequestsMap
    whiteList: string[]
    requestInterceptors: ReqInterceptors
    responseInterceptors: ResInsterceptors
  }) {
    this.serviceInstance = axios.create({
      baseURL: baseURL,
      timeout: 60000,
      paramsSerializer: (params) => {
        return Qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
    try {
      this.serviceInstance.defaults.headers.common['Tns-Referer'] = window.top
        ? window.top.location.href
        : window.location.href
    } catch (error) {
      this.serviceInstance.defaults.headers.common['Tns-Referer'] = window.location.href
      console.error(error)
    }
    this.baseURL = baseURL
    this.preRequestsMap = preRequests
    this.whiteList = whiteList
    this.preRequestPromise = new Promise((resolve, reject) => {
      this.preRequestResolveFun = resolve
      this.preRequestRejectFun = reject
    })
    this.filterPreRequest()

    this.useInterceptReq(requestInterceptors)
    this.useInterceptRes(responseInterceptors)
  }

  filterPreRequest() {
    this.serviceInstance.interceptors.request.use(async (config) => {
      const { url = '' } = config
      // 非 preRequest 需等待所有 preRequest 完成
      if (this.preRequestsMap.size && !this.preRequestsMap.has(url) && this.whiteList.indexOf(url) < 0) {
        await this.preRequestPromise
      }
      return config
    })

    this.serviceInstance.interceptors.response.use((response) => {
      // preRequests 若有任意请求错误，则 promise 会一直处于 pending 状态，走统一的错误处理。其他请求将无法发送
      if (this.preRequestsMap.size > 0) {
        const { url = '' } = response.config
        const callbackFun = this.preRequestsMap.get(url)
        if (callbackFun) {
          callbackFun(response, this.serviceInstance)
          this.preRequestsMap.delete(url)
        }

        if (this.preRequestsMap.size <= 0) {
          this.preRequestResolveFun()
        }
      }
      return response
    })
  }

  useInterceptReq(requestInterceptors: ReqInterceptors) {
    requestInterceptors.forEach(({ reWriteConfig, errHandler }) => {
      this.serviceInstance.interceptors.request.use(
        (config) => reWriteConfig(config),
        (err) => {
          errHandler(err)
        }
      )
    })
  }

  useInterceptRes(responseInterceptors: ResInsterceptors) {
    responseInterceptors.forEach(({ reWriteRes, errHandler }) => {
      this.serviceInstance.interceptors.response.use(
        (response) => reWriteRes(response),
        (err) => {
          errHandler(err)
        }
      )
    })
  }

  public async get(params: IAxiosRequestConfig) {
    const { url = '', data, ...config } = params
    const resData = await this.serviceInstance.get(url, {
      params: data,
      ...config
    })
    //@ts-ignore
    return resData as ResData<any>
  }

  public async post(params: IAxiosRequestConfig) {
    const { url = '', data, ...config } = params
    const resData = await this.serviceInstance.post(url, data, config)
    //@ts-ignore
    return resData as ResData<any>
  }

  public async delete(params: IAxiosRequestConfig) {
    const { url = '', data, ...config } = params
    const resData = await this.serviceInstance.delete(url, {
      data,
      ...config
    })
    //@ts-ignore
    return resData as ResData<any>
  }

  public async put(params: IAxiosRequestConfig) {
    const { url = '', data, ...config } = params
    const resData = await this.serviceInstance.put(url, data, config)
    //@ts-ignore
    return resData as ResData<any>
  }

  public getUrl(params: IAxiosRequestConfig) {
    const { url, data, method } = params
    if (!url) return ''
    const urlObj = new URL(this.baseURL + url)
    if (!method || method.toLowerCase() === 'get') {
      urlObj.search = new URLSearchParams(
        data ? omitBy(normalizeKey.camelCaseToUnderline(data), isUndefined) : undefined
      ).toString()
    }
    return urlObj.href
  }
}
const basicConfig = {
  preRequests: new Map<string, (response: AxiosResponse<ResData<any>>, instance: AxiosInstance) => void>([]),
  whiteList: ['/v1/agent/logout'],
  requestInterceptors: [
    {
      reWriteConfig: (config: AxiosRequestConfig<any>) => {
        try {
          const params = Object.fromEntries(new URLSearchParams(window.location.search)) || {}
          const from = params.from
          config.headers = config.headers || {}
          if (from) {
            config.headers = { 'Tns-From': from, ...config.headers }
          }
          try {
            config.headers['Tns-Referer'] = window.top ? window.top.location.href : window.location.href
          } catch (error) {
            config.headers['Tns-Referer'] = window.location.href
            console.error(error)
          }
        } catch (e) {
          console.error(e)
        }
        return {
          ...config,
          params: config.params ? normalizeKey.camelCaseToUnderline(config.params) : undefined,
          data: config.data ? normalizeKey.camelCaseToUnderline(config.data) : undefined,
          requestStartTime: Date.now()
        }
      },
      errHandler: (err: any) => {
        msgService(336500700, err?.message)
        return err
      }
    }
  ],
  responseInterceptors: [
    {
      // 请求成功
      reWriteRes: (res: AxiosResponse<any, any>) => {
        const { data, config } = res
        const { code, msg } = data as ResData<any>
        const {
          ignoreMsg,
          msgKey,
          msgDuration,
          url = '',
          requestStartTime = 0,
          requestReportExt,
          needReportReqTime
        } = config as IAxiosRequestConfig
        const result = normalizeKey.underlineToCamelCase(data)
        if (code === 0) {
          if (result && result.data?.loginUrl) {
            window.location = result.data.loginUrl
            return
          }
          // 请求成功，上报耗时打点
          if (needReportReqTime) {
            reportRequestTime({
              url,
              ext: requestReportExt,
              duration: Date.now() - requestStartTime
            })
          }
          return result
        } else {
          // 服务器问题，返回异常code，上报失败打点
          reportRequestFail({
            url,
            code,
            message: msg
          })
          if (!ignoreMsg) {
            msgService(code, msg, msgKey, msgDuration)
          }
          throw result
        }
      },
      // 请求失败
      errHandler: (err: any) => {
        const { config, code, message: msg, response = {} } = err
        const { status, data } = response
        if (status === 401) {
          // 需要登录
          const loginUrl = data?.soup_login_url
          const url = new URL(loginUrl)
          if (url.hostname === window.location.hostname) {
            window.location.href = loginUrl
          }
          // iframe访问的情况，如果要跳转到soup->google，则必须从顶层跳转
          ;(window.top || window).location.href = loginUrl
        } else if (status === 403) {
          // ‘/task’页面隐藏没有权限的入口，不需要弹窗提示
          if (window.location.pathname !== '/task') {
            // 登陆了但是没有权限
            msgService(336500700, 'you have no permission')
          }
        } else if (data?.code && errorCodeMap[data.code]) {
          msgService(336500400, errorCodeMap[data?.code])
        } else if (err.code === 'ECONNABORTED' && err.message.includes('timeout')) {
          msgService(336500700, 'Poor Network Connection, pls refresh the page.')
        } else {
          msgService(336500700, err?.message)
        }
        if (status !== 401) {
          reportRequestFail({
            url: config.url,
            code,
            message: msg
          })
        }
        throw err instanceof Error ? err : new Error(err)
      }
    }
  ]
}
export const taskService = new Service({
  ...basicConfig,
  baseURL: TASK_API_BASE_URL
})

export const queueService = new Service({
  ...basicConfig,
  baseURL: QUEUE_API_BASE_URL
})

export const taskApi = {
  get: taskService.get.bind(taskService),
  post: taskService.post.bind(taskService),
  delete: taskService.delete.bind(taskService),
  put: taskService.put.bind(taskService),
  getUrl: taskService.getUrl.bind(taskService)
} as const

export default taskApi

export const queueApi = {
  get: queueService.get.bind(queueService),
  post: queueService.post.bind(queueService),
  delete: queueService.delete.bind(queueService),
  put: queueService.put.bind(queueService)
} as const

export const reportService = new Service({
  ...basicConfig,
  responseInterceptors: [],
  baseURL: TASK_API_BASE_URL
})
export const reportApi = {
  get: reportService.get.bind(reportService),
  post: reportService.post.bind(reportService),
  delete: reportService.delete.bind(reportService),
  put: reportService.put.bind(reportService)
} as const

// template api
export const templateService = new Service({
  ...basicConfig,
  baseURL: TEMPLATE_API_BASE_URL
})

export const templateApi = {
  get: templateService.get.bind(templateService),
  post: templateService.post.bind(templateService),
  delete: templateService.delete.bind(templateService),
  put: templateService.put.bind(templateService)
} as const

export const noBaseService = new Service({
  ...basicConfig,
  baseURL: ''
})

export const noBaseApi = {
  get: noBaseService.get.bind(noBaseService),
  post: noBaseService.post.bind(noBaseService),
  delete: noBaseService.delete.bind(noBaseService),
  put: noBaseService.put.bind(noBaseService)
} as const
